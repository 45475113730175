import { ArrowDownIcon, ArrowUpIcon, ArrowRightIcon } from '@heroicons/react/24/solid';

const styles = {
    position: 'relative',
    marginRight: '3px',
    marginTop: '-15px',
    width: '13px',
};

function Arow(props) {
    switch(props.arrow) {
        case 'up':
            return <ArrowUpIcon style={styles} />
        case 'down':
            return <ArrowDownIcon style={styles} />
        default:
            return <ArrowRightIcon style={styles} />
    }
}

export default Arow