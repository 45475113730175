import './App.css';
//import Temperature from './temperature/Temperature';
import TemperatureBox from './temperature/TemperatureBox';
import imgSolar from './images/solarni-kolektory.jpg';

const imgStyles = {
  //backgroundImage: 'url(${imgSolar})',
  background: "center url(" + imgSolar + ")",
  backgroundSize: 'cover',
  width: '100%',
  height: '370px'
};

const contentStyles = {
  position: 'relative',
  marginTop: '-30px',
  borderRadius: '30px',
  background: 'white',
  padding: '20px'
};

function App() {  
  return (
    <div className="App">
      <section style={imgStyles} />
      <section style={contentStyles}>
        <h1>Solární panely</h1>
        <p style={{padding: '10px', lineHeight: '130%'}}>
          Teplota vody ohřívaná solárními kolektory na střeše domu Vlčkových. Aktualizace probíhá každých 10 minut. 
        </p>
        <TemperatureBox />
      </section>
    </div>
  );
}

export default App;