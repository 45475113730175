import { useState, useEffect } from 'react';
import Moment from 'moment';
import Arow from './Arrow';

const url = "https://arduino.alsan.cz/api/v1/chart/GET/";

function TemperatureBox() {
    const [start, setStart] = useState(true);
    const [temperature, setTemperature] = useState(
        {
            'current': 0,
            'old': 0,
            'date': null,
            'dateFromNow': null,
            'arrow': null,
            'state': 'cold',
            'chartData': [],
            'dayMax': null
        }
    );

    const boxStyles = {
        backgroundColor: temperature.current >= 50 ? 'red' : (temperature.current >= 40 ? '#FF7A00' : (temperature.current !== 0 ? '#0075FF' : 'silver')),
        borderRadius: '30px',
        padding: '5px',
        fontSize: '20px',
        color: 'white',
        marginTop: '20px',
        marginBottom: '3px'
    };

    const makeAPICall = async () => {
        console.log('api call...');
        try {
            const response = await fetch(url, {mode:'cors'});
            const jsonData = await response.json();
            console.log(jsonData);
            setTemperature(
                {
                    'current': parseFloat(jsonData.temperature).toFixed(1),
                    'old': jsonData.temperatureOld,
                    'date': jsonData.date,
                    'dateFromNow': Moment(jsonData.date).fromNow(),
                    'arrow': jsonData.temperature < jsonData.temperatureOld ? 'down' : (jsonData.temperature > jsonData.temperatureOld ? 'up' : 'right'),
                    'state': jsonData.temperature < 40 ? 'cold' : (jsonData.temperature > 50 ? 'hot' : 'warm'),
                    'chartData': jsonData.data,
                    'dayMax': parseFloat(jsonData.max).toFixed(1)
                }
            );
        }
        catch (e) {
            console.log(e)
            //setTemperature({'dayMax': null});
        }
    }

    useEffect(() => {
        const interval = setInterval(() => makeAPICall(), 60000);
        return () => clearInterval(interval);
    }, [])
    
    if (start === true) {
        setStart(false);
        makeAPICall();
    }

    return (
        <div>
            <div style={boxStyles}>
                <Arow arrow={temperature.arrow} />
                {temperature.current} °C
            </div>
            <small>{temperature.dateFromNow}</small>

            <div className='d-flex flex-col align-items-end justify-content-center mt-0' style={{height: '50px'}}>
                {Object.keys(temperature.chartData).map((item, index) => (
                    <div style={{width: '10px', borderRadius: '0px', backgroundColor: '#e4e4e4', paddingTop: '2px', marginRight: '1px', height: (temperature.chartData[item] /2) + 'px'}}></div>
                ))}
            </div>
            <small>Denní max. {temperature.dayMax} °C</small>
        </div>
    )
}

export default TemperatureBox;